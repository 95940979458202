import logo from './logo.svg';
import './App.css';
import Landing from './pages/Landing/Landing';
import MetaTags from 'react-meta-tags';

function App() {
  return (
    <div className='App'>
      <MetaTags>
        <title>Hyperlane</title>
        <meta
          name='viewport'
          content='width=device-width, initial-scale = 1.0,maximum-scale=1.0, user-scalable=no'
        />
        <meta
          id='meta-description'
          name='description'
          content='Powering the digital economy'
        />
        <meta id='og-title' property='og:title' content='Hyperlane finance' />
      </MetaTags>
      <Landing />
    </div>
  );
}

export default App;
