import React from 'react';
import styles from './Landing.module.css';
import Warp from '../../components/Warp/Warp';
import Typing from '../../components/Typing/Typing';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/Card';
import Heading from '../../components/Heading/Heading';
import Text from '../../components/Text/Text';
import Footer from '../../components/Footer/Footer';
import Trading from '../../assets/trade.svg';
import Stake from '../../assets/earn.svg';
import Arbitrage from '../../assets/arb.svg';

export default function Landing() {
  return (
    <div>
      <Warp />
      <div className={styles.typingWrapper}>
        <Typing />
      </div>
      <div className={styles.heroButtonWrapper}>
        <Button title='Learn more' anchor='#learnmore' />
        {/* <Button title='Products' anchor='#roadmap' />
        <Button title='Credit for play to earn' anchor='#dots' /> */}
      </div>
      <div className={styles.sectionWrapper} id='learnmore'>
        <Heading text='How does it work' />
        <section className={styles.howItWorks}>
          <Card
            title='Open a leverage account and deposit collateral'
            description='A Leverage Account is an isolated smart contract that contains both the user funds and the borrowed funds. All operations go through it. With a leverage account users retain custody of their funds.'
          />
          <Card
            title='Get leveraged'
            description='Depending on the use case and current market conditions, users will be able to borrow from 2 to 10x the amount deposited. This debt will accrue interests just like borrowing on aave does.'
          />
          <Card
            title='Unleash your strategy'
            description='The protocol will provide a user interface and the corresponding backend logic for  '
            linktext='each product'
            rest='so the user doesn’t have to experience the real complexity of the system.'
          />
          <div className={styles.textSeparator}>
            <Text text='When great individuals have access to resources great things get done. Now we can offer the same opportunities in the digital space.' />
          </div>
        </section>
      </div>
      <div className={styles.sectionWrapper}>
        <Heading text='What can be bought with hyperlane credit' />
        <div className={styles.textSeparator}>
          <Text text='Any liquid asset which price can be monitored in real time.' />
          <div className={styles.smallTextContainer}>
            <Text small={true} text='ERC20 TOKENS 0X...XXX' />
            <Text small={true} text='FRACTIONALIZED NFTs 0x...XXX' />
            <Text small={true} text='PLAY TO EARN NFTs 0x...XXX' />
            <Text small={true} text='METAVERSE PROPERTY 0x...XXX' />
            <Text small={true} text='SYNTHETIC ASSETS 0X...XXX' />
            <Text small={true} text='LEVERAGED TOKENS 0X...XXX' />
            <Text small={true} text='DERIVATIVES 0X...XXX' />
            <Text small={true} text='MORE 0X...XXX' />
          </div>
        </div>
      </div>
      <div className={styles.sectionWrapper} id='roadmap'>
        <Heading text="Hyperlane's baseline products roadmap" />
        <div className={styles.separator}></div>
        <section className={styles.useCase}>
          <Heading hiddenLarge={true} green={true} text='Q1 2022' />

          <div className={styles.useCaseDescription}>
            <Heading text='Margin trading' />
            <div className={styles.textSeparator}>
              <Text text='Borrow up to 5x your collateral and go long or short on more than 150 ERC20 tokens. No KYC margin trading with a "Pro" trading experience. Set limit and stop-loss orders to automatically manage risk.' />
            </div>
          </div>
          <div className={styles.useCaseImage}>
            {/* <img className={styles.useCase} src={Trading} /> */}
            <Heading hidden={true} green={true} text='Q1 2022' />
          </div>
        </section>
        <Heading text='.' />
        <Heading text='.' />
        <Heading text='.' />
        <section className={styles.useCase}>
          <div className={styles.useCaseImage}>
            <Heading green={true} text='Q2 2022' />
            {/* <img className={styles.useCase} src={Stake} /> */}
          </div>
          <div className={styles.useCaseDescription}>
            <Heading text='Leveraged staking' />
            <div className={styles.textSeparator}>
              <Text text='Enter on a leveraged position on staking platforms like Olympus DAO and TIME Wonderland.' />
            </div>
          </div>
        </section>
        <section className={styles.useCase}>
          <div className={styles.useCaseDescription}>
            <Heading text='Leveraged lottery participation' />
            <div className={styles.textSeparator}>
              <Text text='Get up to a 20x leveraged position for 0 risk lotteries like the ones on pooltogether.com and dramatically increase your chances of winning.' />
            </div>
          </div>
          <div className={styles.useCaseImage}>
            {/* <img className={styles.useCase} src={Trading} /> */}
          </div>
        </section>
        <section className={styles.useCase}>
          <div className={styles.useCaseImage}>
            {/* <img className={styles.useCase} src={Stake} /> */}
          </div>
          <div className={styles.useCaseDescription}>
            <Heading text='Leveraged farming' />
            <div className={styles.textSeparator}>
              <Text text='leverage your position in yield farming pools. Lenders can earn high interest on their assets, and yield farmers can get even higher farming APY from taking on leveraged positions on yield farming.' />
            </div>
          </div>
        </section>
        <Heading text='.' />
        <Heading text='.' />
        <Heading text='.' />
        <section className={styles.useCase}>
          <Heading hiddenLarge={true} green={true} text='Q3 2022' />

          <div className={styles.useCaseDescription}>
            <Heading text='Leveraged trading of fractionalized NFTs' />
            <div className={styles.textSeparator}>
              <Text text='Get leveraged price exposure to sought-after NFT collections by going long or short on fractionalized NFTs.' />
            </div>
          </div>

          <div className={styles.useCaseImage}>
            <Heading hidden={true} green={true} text='Q3 2022' />
            {/* <img className={styles.useCase} src={Trading} /> */}
          </div>
        </section>

        <section className={styles.useCase}>
          <div className={styles.useCaseImage}>
            {/* <img className={styles.useCase} src={Arbitrage} /> */}
          </div>
          <div className={styles.useCaseDescription}>
            <Heading text='Leveraged arbitrage' />
            <div className={styles.textSeparator}>
              <Text text='Perform leveraged arbitrage of correlated assets like DAI and USDC turning a 1% price difference into a 10% profit.' />
            </div>
          </div>
        </section>
      </div>
      <div className={`${styles.z2} ${styles.dots}`} id='dots'>
        <Heading text='.' />
        <Heading text='.' />
        <Heading text='.' />
      </div>
      <div className={`${styles.sectionWrapper} ${styles.axie}`}>
        <div className={styles.overlay}></div>

        <section className={`${styles.howItWorks2} ${styles.z2}`}>
          <div>
            <Heading text='A new, exciting way to buy and manage digital property.' />
            <div className={styles.separator}></div>
            <div className={styles.separator}></div>
          </div>
          {/* <div className={styles.textSeparator}>
            <Text text='NTFs are digital property. Hyperlanes believes digital property should enjoy the same advantages of traditional property.' />
          </div> */}
          <div className={styles.useCaseDescription2}>
            <Heading text='Buy Play to Earn and Metaverse property with credit' />
            <div className={styles.separator}></div>
            <div className={styles.textSeparator}>
              <Text text='Starting with Axie infinity as a proof of concept.' />
            </div>
            <div className={styles.textSeparator}>
              <Text text='Users will be able to deposit a fraction of an Axie Team’s cost as collateral, get up to 3x leverage to buy their first Axies and start playing, while using their profits to repay the debt to fully own them.' />
            </div>
            <div className={styles.textSeparator}>
              <Text text='This allows people to buy, own and work with their digital property for profit. Stop relying on Axie infinity exploiters and own what you earn.' />
            </div>
          </div>
          <div className={styles.separator}></div>
          <div className={styles.useCaseDescription2}>
            <Heading text='Mortgage your digital property' />
            <div className={styles.separator}></div>
            <div className={styles.textSeparator}>
              <Text text='We will allow users to use their metaverse and play to earn land -and other assets- as collateral to borrow funds so they can finance their strategies, whether in-game or outside of it.' />
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
